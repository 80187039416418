<template>
    <section id="dashboard-campaigns">
        <b-row class="title mb-5" v-if="$role(['campaigns.create'])">
            <b-col>
                <card-header v-bind="cardHeaderOptions">
                    <template v-slot:button>
                        <button v-b-modal.createCampaignModal class="text-truncate font-weight-bolder btn btn-primary">
                            Criar Projeto de Campanha
                        </button>
                    </template>
                </card-header>
            </b-col>
        </b-row>
        <b-row class="mb-5">
            <b-col v-for="opt in options" :key="opt.id" class="my-2">
                <custom-options
                    :class="[{ active: currentOpt.id == opt.id }, 'option']"
                    :opt="opt"
                    v-on:click.native="currentOpt = opt"
                />
            </b-col>
        </b-row>
        <b-row class="mb-5 position-relative">
            <b-col>
               <campaign-table></campaign-table>
            </b-col>
        </b-row>
        <b-row class="mb-5">
            <b-col>
                <b-card class="text-center">
                    <loading v-if="configTableSegmentation.loading"/>
                    <common-table-segmentation
                        :show-details="false"
                        title="Segmentações recentes"
                        v-bind="configTableSegmentation"
                        @update="handleUpdatedItems">
                        <template v-slot:filters>
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink">
                                    <select v-model="configTableSegmentation.filters.order_column"
                                            class="form-control like-dropdown my-1">
                                        <option :value="null" hidden selected>Filtrar por</option>
                                        <option value="name">Nome</option>
                                        <option value="created_at">Data de Criação</option>
                                    </select>
                                </div>
                                <div class="flex-shrink">
                                    <select v-model="configTableSegmentation.filters.order"
                                            class="form-control like-dropdown mx-md-2 my-1">
                                        <option :value="null" hidden selected>Classificar por</option>
                                        <option value="asc">Ascendente</option>
                                        <option value="desc">Descendente</option>
                                    </select>
                                </div>
                                <div class="input-group input-group-merge search my-1 w-100 flex-grow-1" role="group"
                                     style="width: 300px !important;">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <feather-icon icon="SearchIcon" size="25"/>
                                        </div>
                                    </div>
                                    <input
                                        v-model="configTableSegmentation.filters.search"
                                        class="form-control"
                                        placeholder="Buscar segmentação"
                                        type="text"
                                    >
                                </div>
                            </div>
                        </template>
                        <template v-slot:footer>
                            <td class="text-left">Total</td>
                            <td>{{ configTableSegmentation.misc ? configTableSegmentation.misc.total : 0 }}</td>
                            <td :colspan="configTableSegmentation.items.length - 2" class="text-right">
                                <b-link
                                    v-if="configTableSegmentation.misc
                                    && configTableSegmentation.misc.current_page !== configTableSegmentation.misc.last_page"
                                    :to="{ name: '' }"
                                    @click="getSegmentations(`${configTableSegmentation.misc.current_page + 1}`)">
                                    + Mostrar mais
                                </b-link>
                            </td>
                        </template>
                    </common-table-segmentation>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <shot-table />
            </b-col>
        </b-row>
        <create-new-campaign-modal/>
    </section>
</template>

<script>
import {BRow, BCol, BCard, BLink} from "bootstrap-vue";
import CustomOptions from "@/views/components/CustomOptions.vue";
import CardHeader from "@/views/components/CardHeader.vue";
import Loading from "@/views/components/Loading.vue";
import CreateNewCampaignModal from "@/views/pages/dashboard/campaigns/components/CreateNewCampaignModal.vue";
import api from "@/api";
import {configTableSegmentation} from './constants';
import CommonTableSegmentation from "@/views/pages/dashboard/segmentation/commonTableSegmentation.vue";
import CampaignTable from "@/views/pages/dashboard/campaign_content/CampaignTable.vue";
import ShotTable from "@/views/pages/dashboard/campaigns/components/ShotTable.vue";

let options = [
    {
        id: 1,
        title: "Conteúdo",
        description: "Configure os conteúdos das suas campanhas.",
        icon: require("@/assets/images/icons/index.svg"),
        link: "campaigns/contents",
        isButton: true,
        textButton: "Entrar",
    },
    {
        id: 2,
        title: "Segmentações",
        description: "Crie ou edite segmentações para as suas campanhas.",
        icon: require("@/assets/images/icons/filter-and-sort.svg"),
        link: "segmentation",
        isButton: true,
        textButton: "Entrar",
    },
    {
        id: 3,
        title: "Disparos",
        description: "Aqui você realiza ou agenda novos disparos de campanhas.",
        icon: require("@/assets/images/icons/chat-message-sent.svg"),
        link: "shots/patterns",
        isButton: true,
        textButton: "Entrar",
    },
];


export default {
    components: {
        BRow,
        BCol,
        BCard,
        BLink,
        CustomOptions,
        CardHeader,
        CreateNewCampaignModal,
        CommonTableSegmentation,
        Loading,
        ShotTable,
        CampaignTable
    },
    data() {
        return {
            data: {},
            configTableSegmentation: {
                ...configTableSegmentation,
                filters: {
                    order_column: 'created_at',
                    order: 'desc',
                    search: null,
                    limit: 5
                },
                timeout: null
            },
            options: options,
            currentOpt: options[0],
            cardHeaderOptions: {
                title: "Criar Campanha",
                description:
                    "Agende de forma rápida e fácil suas campanhas, utilizando configurações já estabelecidas ou criando novas.",
                icon: require("@/assets/images/icons/add-list.svg"),
                link: "register",
                textButton: "Criar projeto de campanha",
            },
            loading: {
                campaigns: false,
                segmentation: false
            }
        };
    },
    mounted() {
        this.getSegmentations();
    },
    watch: {
        "configTableSegmentation.filters": {
            handler() {
                clearTimeout(this.configTableSegmentation.timeout);
                this.configTableSegmentation.timeout = setTimeout(this.getSegmentations, 500);
            },
            deep: true
        },
    },
    methods: {
        getSegmentations(page = 1) {
            this.configTableSegmentation.loading = true;
            const params = {page, ...this.configTableSegmentation.filters};

            api.get('/segmentation/', {params})
                .then(response => {
                    const {type, body, misc} = response.data;
                    if (type !== 'success') return;
                    if (page > 1) this.configTableSegmentation.items.push(...body);
                    else this.configTableSegmentation.items = body;

                    this.configTableSegmentation.misc = misc;
                })
                .finally(() => {
                    this.configTableSegmentation.loading = false;
                })
        },
        handleUpdatedItems(data) {
            this.configTableSegmentation.items = data.body;
            this.configTableSegmentation.misc = data.misc ?? this.configTableSegmentation.misc;
            this.configTableSegmentation.loading = data.loading;
        },
    },
};
</script>
