<template>
    <div class="common-table">
        <loading v-if="loading"/>
        <!-- Open Header -->
        <div
            v-if="title || filters"
            class="w-100 d-flex justify-content-between my-4 header-custom-table flex-wrap"
        >
            <slot name="title">
                <h3 v-if="title" class="text-blue font-weight-bolder flex-grow-1 text-left">
                    {{ title }}
                </h3>
            </slot>
            <slot name="filters">
                <b-form-group v-if="filters">
                </b-form-group>
            </slot>
        </div>
        <!-- Close Header -->
        <b-table
            ref="segmentationTable"
            :borderless="true"
            :fields="fields"
            :items="items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sticky-header="true"
            responsive="sm"
            thead-tr-class="text-blue"
            show-empty
        >
            <template #cell(name)="data">
                <span class="name text-black">{{ data.value }}</span>
            </template>

            <template #cell(coletados)="data">
                {{ data.value }}
            </template>

            <template #cell(created_at)="data">
                {{ formatDate(data.value) }}
            </template>

            <template #empty>
                <p class="m-0 text-center">Nenhum registro encontrado</p>
            </template>

            <template #cell(actions)="data">
                <b-link
                    v-for="action in actions"
                    :key="action.text"
                    :to="{ name: action.link, params: {id: data['item']['_id']} }"
                    class="mr-1"
                >
                    <span v-if="action.text === 'Duplicar'"
                          @click.prevent="duplicateSegmentation(`${data['item']['_id']}`)"
                    >
                        {{ action.text }}
                    </span>

                    <span v-else-if="action.text === 'Deletar'"
                          @click.prevent="deleteSegmentation(`${data['item']['_id']}`)"
                    >
                        {{ action.text }}
                    </span>
                    <span v-else-if="action.text === 'Atualizar'"
                          @click.prevent="updateSegmentationShots(`${data['item']['_id']}`)"
                    >
                        {{ action.text }}
                    </span>

                    <span v-else>{{ action.text }}</span>
                </b-link>
                <b-link v-if="showDetails" @click="data.toggleDetails">
                    <font-awesome-icon
                        :icon="
                            data.detailsShowing
                                ? 'fa-solid fa-caret-up'
                                : 'fa-solid fa-caret-down'
                        "
                    />
                </b-link>
            </template>

            <template #row-details>
                <b-card></b-card>
            </template>

            <template #custom-foot :columns="items.length">
                <slot name="footer"/>
            </template>
        </b-table>
    </div>
</template>

<script>
import {
    BTable,
    BCard,
    BFormGroup,
    BLink,
} from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";
import api from "@/api";
import _ from "lodash";
import moment from "moment";

export default {
    props: {
        title: String,
        filters: Object,
        fields: Array,
        items: Array,
        actions: Array,
        loading: Boolean,
        showDetails: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            optionsOrder: [
                {label: "Asc", code: true},
                {label: "Desc", code: false},
            ],
            sortBy: "",
            sortDesc: "",
            sortDirection: "asc",
            filter: "",
        };
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return {label: f.label, code: f.key};
                });
        },
    },
    components: {
        BTable,
        BLink,
        BCard,
        BFormGroup,
        Loading
    },

    methods: {
        duplicateSegmentation(id) {
            this.$swal({
                title: 'Tem certeza?',
                text: 'Deseja duplicar a segmentação selecionada?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Duplicar',
                confirmButtonColor: "#317f98",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return api.post(`/segmentation/duplicate/${id}`);
                }
            })
                .then((result) => {
                    if (result.isConfirmed && result.value.data.type) {
                        const type = result.value.data.type === 'success' ? 'success' : 'danger';
                        this.segmentationToast(result.value.data.message, type)
                        this.items = this.items.unshift(result.value.data.body);
                    }
                })
        },

        deleteSegmentation(id) {
            this.$swal({
                title: 'Tem certeza?',
                text: 'Deseja remover a segmentação selecionada?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Remover',
                confirmButtonColor: "#317f98",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return api.delete(`/segmentation/delete/${id}`);
                }
            })
                .then((result) => {
                    if (result.isConfirmed && result.value.data.type) {
                        this.items = this.items.filter(segmentation => segmentation._id !== id);
                        this.$refs.segmentationTable.refresh();
                        const type = result.value.data.type === 'success' ? 'success' : 'danger';
                        this.segmentationToast(result.value.data.message, type)
                        this.$emit('update', {body: this.items});
                    }
                })
        },

        async getSegmentations(search = '') {
            this.$emit('update', {body: {...this.items}, loading: true});
            let params = {
                search: search
            }

            await api.get('/segmentation/', {params})
                .then(response => {
                    const items = response.data;

                    this.$emit('update', items);
                })
                .finally(() => {
                    this.$refs.segmentationTable.refresh();
                })
        },

        segmentationToast(message, type) {
            this.$bvToast.toast(message, {
                title: 'Segmentação',
                autoHideDelay: 2000,
                variant: type
            });
        },

        searchFilter: _.debounce(function () {
            this.getSegmentations(this.filter);
        }, 500),

        formatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        },

        updateSegmentationShots(id) {
            this.$emit('update', {loading: true});
            api.put(`/segmentation/update_shots/${id}`)
                .then((response) => {
                    const {message, type} = response.data;
                    this.segmentationToast(message, type);
                    this.getSegmentations();
                })
        }
    },
    watch: {
        filter: {
            handler() {
                this.searchFilter();
            }
        },
    }
};
</script>

<style lang="scss">
.custom-dropdown {
    .vs__selected {
        position: absolute;
    }
}
</style>
