<template>
    <b-modal id="createCampaignModal" centered hide-footer size="md">
        <div class="px-3">
            <div class="d-flex justify-content-center">
                <b-avatar size="8rem" variant="light">
                    <img class="d-block img-fluid" src="@/assets/images/icons/add-list.svg"/>
                </b-avatar>
            </div>

            <h4 v-if="!fromPrevious" class="text-blue text-center my-4">
                Deseja criar uma campanha totalmente nova ou a partir de uma anterior?
            </h4>

            <div v-if="!fromPrevious" class="row my-4">
                <router-link
                    v-slot="{ navigate }"
                    :to="{ name: 'campaigns/new' }"
                    custom
                >
                    <button class="offset-md-2 col-12 col-md-8 my-1 btn btn-outline-primary" @click="navigate">
                        Completamente Nova
                    </button>
                </router-link>
                <button class="offset-md-2 col-12 col-md-8 my-1 btn btn-primary"
                        @click.prevent="fromPrevious = !fromPrevious">
                    Baseada em Anterior
                </button>
            </div>
            <div v-if="fromPrevious">
                <validation-observer ref="addNewCampaign">
                    <form>
                        <validation-provider
                            #default="{ errors }"
                            class="row my-4"
                            name="campanha"
                            rules="required"
                        >
                            <input :value="campaign._id" hidden type="text">
                            <v-select
                                v-model="campaign._id"
                                :class="`${errors.length > 0 && 'is-invalid'}`"
                                :clearable="true"
                                @search="onSearch"
                                :get-option-label="(option) => option.name"
                                :options="campaigns"
                                :reduce="(option) => option._id"
                                :loading="loading.campaign"
                                :selectable="(option) => !campaigns.includes(option._id)"
                                class="offset-md-2 col-12 col-md-8 my-1"
                                placeholder="Selecione uma campanha"
                            >
                                <span slot="no-options">Desculpe, não há opções correspondentes.</span>
                            </v-select>
                            <small class="invalid-feedback offset-md-2 col-12 col-md-8 my-1">{{ errors[0] }}</small>
                        </validation-provider>
                    </form>
                </validation-observer>
                <div class="row align-items-center justify-content-end">
                    <div class="col-12 col-md-4 d-flex mt-2">
                        <button class="btn btn-outline-primary flex-grow-1 mr-2" @click="fromPrevious = !fromPrevious">
                            Cancelar
                        </button>
                        <button :disabled="creating" class="btn btn-primary" @click="newCampaign">Criar</button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import {BModal, BAvatar} from "bootstrap-vue";
import VSelect from "vue-select";
import api from "@/api";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "@validations";
import _ from "lodash";

const campaignDefault = {
    name: null,
    _id: null
};

export default {
    components: {
        BModal,
        BAvatar,
        VSelect,
        ValidationObserver,
        ValidationProvider
    },

    data() {
        return {
            fromPrevious: false,
            timeout: null,
            campaign: {...campaignDefault},
            campaigns: [],
            loading: {
                campaign: false
            },
            creating: false,
            required
        }
    },

    watch: {
        fromPrevious: {
            handler(val) {
                if (val) {
                    clearTimeout(this.timeout);
                    this.reset();
                    this.timeout = setTimeout(this.getCampaigns(), 500);
                }
            }
        }
    },

    methods: {
        getCampaigns(search = null, loading = false) {
            this.loading.campaign = true;
            const params = {
                order_column: 'created_at',
                order: 'desc',
            }

            if (search !== null && search !== "") {
                params.search = search;
            }

            api.get('/campaigns', {params})
                .then(response => {
                    this.campaigns = response.data.body;
                })
                .catch(error => {
                    if (error.response) {
                        const {errors, message} = error.response.data;
                        return this.$swal(message, errors.length > 0 ? errors[0] : '', 'error');
                    }
                    return this.$swal(
                        'Houve um problema',
                        'Não foi possível processar sua requisição corretamente. Tente novamente mais tarde.',
                        'error'
                    );
                })
                .finally(() => {
                    this.loading.campaign = false;
                    if (loading) {
                        loading(false);
                    }
                })
        },

        newCampaign() {
            this.$refs.addNewCampaign.validate().then((success) => {
                if (success) {
                    this.creating = true;
                    const id = this.campaign._id;
                    api.post(`/campaigns/${id}`)
                        .then(response => {
                            const {body} = response.data;
                            this.$router.push({name: 'campaigns/edit', params: {id: body._id}})
                        })
                        .catch(error => {
                            if (error.response) {
                                const {errors, message} = error.response.data;
                                return this.$swal(message, errors.length > 0 ? errors[0] : '', 'error');
                            }
                            return this.$swal(
                                'Houve um problema',
                                'Não foi possível processar sua requisição corretamente. Tente novamente mais tarde.',
                                'error'
                            );
                        })
                        .finally(this.creating = false);
                }

            });
        },

        reset() {
            this.campaign = {...campaignDefault};
        },

        onSearch(search, loading) {
            if(this.campaign._id == null) {
                loading(true);
                this.search(search, loading);
            }
        },
        search: _.debounce(function (search, loading) {
            this.getCampaigns(search, loading);
        }, 500)
    }
}
</script>

<style lang="css" scoped>
.is-invalid .vs__dropdown-toggle {
    border: 1px solid red !important;
}
</style>
